module.exports = {
  siteTitle: 'Okan Sahin | Full Stack Developer',
  siteDescription:
    'Okan Sahin is a full stack software developer who works freelance. Currently I\'m familiar with k8s, docker, javascript, mysql, mongo, php, nodejs, react, vue, ObjC.',
  siteKeywords:
    'Okan Sahin, Okan, Sahin, okansahin, software developer, full stack developer, web&mobile developer, javascript',
  siteUrl: 'https://okansahin.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-161712995-1',
  googleVerification: 'LPL1bAe5HuBxBU6Pka7soSHtrl2rKEMq659JL0NrOi8',
  name: 'Okan Sahin',
  location: 'Planet Earth',
  email: 'hello@okansahin.com',
  github: 'https://github.com/okansahin',
  twitterHandle: '@oknshn',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/okansahin',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/okansahin',
    },
    {
      name: 'StackOverflow',
      url: 'https://stackoverflow.com/users/1010224/okan',
    },
    {
      name: 'SoundCloud',
      url: 'https://www.soundcloud.com/okansahin',
    },
    /*
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/okansahin',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/oknshn',
    },*/
  ],
  /*
  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],*/

  navHeight: 100,
  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
