import React from 'react';
import PropTypes from 'prop-types';
import { socialMedia } from '@config';
import { FormattedIcon } from '@components/icons';
import styled from 'styled-components';
import { media } from '@styles';
import style from './social.module.css';

const StyledList = styled.ul`
  display: none;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  li:first-of-type a {
    padding-left: 0px;
  }
  ${media.phablet`
    display: flex;
  `};
  ${media.phone`
    display: flex;
    justify-content:center;
  `};
`;

const StyledLink = styled.a`
  padding: 10px;
  &:hover,
  &:focus {
    transform: translateY(-3px);
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;

const SocialMobile = () => (
  <div>
    <StyledList>
      {socialMedia &&
        socialMedia.map(({ url, name }, i) => (
          <li key={i}>
            <StyledLink
              href={url}
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label={name}
              className={style[name]}>
              <FormattedIcon name={name} />
            </StyledLink>
          </li>
        ))}
    </StyledList>
  </div>
);

SocialMobile.propTypes = {
  isHome: PropTypes.bool,
};

export default SocialMobile;
