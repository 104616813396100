import React from 'react';

const IconDocker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1">
    <path
      d="M20 9v5H10v5H5v5H1.125a.974.974 0 0 0-.969.813A11.73 11.73 0 0 0 0 26.75c0 .7.066 1.46.188 2.219 1.144-.274 3.242-.684 2.874-2.032 1.973 2.286 6.708 1.594 7.907.47 1.34 1.94 9.144 1.198 9.687-.313 1.68 1.968 6.887 1.968 8.563 0 .543 1.511 8.316 2.254 9.656.312.426.399 1.313.73 2.344.907.347-.66.668-1.325.968-2.032 6.352-.078 7.723-4.644 7.782-4.843.11-.383-.04-.778-.344-1.032-.105-.09-2.45-1.992-6.25-1.343-1.066-3.473-3.813-5.055-3.938-5.125a.991.991 0 0 0-1.124.093c-.102.082-2.465 2.086-2.094 6.188a7.371 7.371 0 0 0 .781 2.75c-.82.457-2.23 1.031-4.5 1.031H32v-5h-5V9zm21.219 19.313c-.121.234-.278.46-.407.687h9.032c-1.086-.273-3.418-.64-3.032-2.063-1.277 1.477-3.703 1.696-5.593 1.375zm-.407.687H.188a15.645 15.645 0 0 0 1.5 4.5c5.43 1.277 11.128-.668 11.187-.688.523-.183 1.07.106 1.25.626.184.519-.074 1.101-.594 1.28-.191.067-3.625 1.22-7.844 1.22a21.13 21.13 0 0 1-2.593-.157C5.719 39.261 10.168 42 17 42c10.805 0 19.113-4.59 23.813-13zM.188 29c-.004-.016.003-.016 0-.031-.067.015-.13.015-.188.031zM22 11h3v3h-3zm-10 5h3v3h-3zm5 0h3v3h-3zm5 0h3v3h-3zM7 21h3v3H7zm5 0h3v3h-3zm5 0h3v3h-3zm5 0h3v3h-3zm5 0h3v3h-3zM16 31c.129 0 .262.02.375.063-.121.07-.219.19-.219.343 0 .227.18.438.407.438a.449.449 0 0 0 .375-.219c.05.117.062.238.062.375 0 .55-.45 1-1 1s-1-.45-1-1 .45-1 1-1z"
      id="surface1"
    />
  </svg>
);

export default IconDocker;
