import React from 'react';

const IconStackoverflow = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    x="0px"
    y="0px"
    viewBox="0 0 100 80">
    <title>Stackoverflow</title>
    <path
      d="M22.089,72.898l51.458,10.815l2.163-10.292L24.252,62.606L22.089,72.898z M28.897,48.259l47.666,22.199
	l4.44-9.533L33.337,38.726L28.897,48.259z M42.088,24.874l40.405,33.65l6.73-8.081l-40.405-33.65L42.088,24.874z M68.171,0
	l-8.438,6.276l31.381,42.191l8.438-6.276L68.171,0z M21.044,96.833l52.582,0V86.316l-52.582,0V96.833z"
    />
  </svg>
);

export default IconStackoverflow;
