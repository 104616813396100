import styled from 'styled-components';
import media from './media';

const Section = styled.section`
  margin: 0 auto;
  padding: 0px 0;
  max-width: 1000px;
  justify-content: center;
  ${media.tablet`padding: 0;`};
  ${media.phone`padding: 0;`};
`;

export default Section;
